import { useEffect, useMemo, useState } from "react"

import { faFileInvoice } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { CircularProgress, Dialog, DialogContent, Stack, Typography } from "@mui/material"
import "@toast-ui/editor/dist/i18n/ja-jp"
import "@toast-ui/editor/dist/theme/toastui-editor-dark.css"
import "@toast-ui/editor/dist/toastui-editor.css"
import { PostForm } from "components/Taiyoro/Posts/PostForm"
import { PostImages } from "components/Taiyoro/Posts/PostImages"
import { usePostForm } from "components/Taiyoro/Posts/usePostForm"
import moment from "moment"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { createPostPreview, updatePost } from "services/Taiyoro/posts"
import type { Post, PostImage } from "services/Taiyoro/posts"
import { fetchPost } from "services/Taiyoro/posts"
import { NEW_TAIYORO_DOMAIN } from "utils/taiyoro"
import { openLinkInNewTab } from "utils/tools"
import { v4 as uuidv4 } from "uuid"

type PostParams = {
  postId: string
}

const PREVIEW_SECRET = "oIkrilG6lAY5+w=="

const PostDetailsPage = () => {
  const { t, i18n } = useTranslation("taiyoro")
  const [loading, setLoading] = useState(true)
  const [waiting, setWaiting] = useState(false)
  const { postId } = useParams<PostParams>()
  const [images, setImages] = useState<Array<PostImage>>([])

  const {
    formState,
    updateTextField,
    validateTextField,
    updatePublicationStatus,
    updateAll,
    setPristine,
    setAllPristine,
  } = usePostForm()

  const formStatePristine = useMemo(() => {
    for (const val of Object.values(formState)) {
      if (val.dirty) return false
    }
    return true
  }, [formState])

  useEffect(() => {
    setLoading(true)
    if (postId) {
      fetchPost({ id: postId })
        .then((res) => {
          if (res.status === "success") {
            setImages(res.data.images)
            updateAll({
              ...res.data,
              publicationDatetime: moment(res.data.publicationDatetime).format("YYYY-MM-DDTHH:mm:ss"),
            })
          }
        })
        .finally(() => {
          setLoading(false)
        })
    }
    // eslint-disable-next-line
  }, [postId])

  function handleSave() {
    setWaiting(true)

    const data: Pick<Post, "id"> & Partial<Post> = {
      id: postId,
    }

    Object.keys(formState).forEach((key) => {
      if (formState[key].dirty) {
        data[key] = formState[key].value
      }
    })

    updatePost(data)
      .then((res) => {
        if (res.status === "success") {
          setAllPristine()
        }
      })
      .finally(() => {
        setWaiting(false)
      })
  }

  function handlePreview() {
    setWaiting(true)
    const data: Pick<Post, "id"> & Partial<Post> = {
      id: uuidv4(),
    }
    Object.keys(formState).forEach((key) => {
      data[key] = formState[key].value
    })
    createPostPreview(data)
      .then((previewDataId) => {
        const url = new URL(
          (process.env.REACT_APP_TAIYORO_DOMAIN
            ? `${process.env.REACT_APP_TAIYORO_DOMAIN}/new`
            : "http://localhost:3000/new") + "/api/draft"
        )
        const slug = `/${i18n.language}/post/${data.type!}/${previewDataId}/${moment(data.publicationDatetime).format("YYYY/MM/DD")}/${data.slug!}`
        url.searchParams.append("slug", slug)
        url.searchParams.append("secret", PREVIEW_SECRET)
        openLinkInNewTab(url.toString())
      })
      .finally(() => {
        setWaiting(false)
      })
  }

  function refreshImages(images: Array<PostImage>) {
    setImages(images)
  }

  function clearOgImage() {
    setWaiting(true)
    updateTextField("ogImage", "")
    updatePost({ id: postId, ogImage: null })
      .then((res) => {
        if (res.status === "success") {
          setPristine("ogImage")
        }
      })
      .finally(() => {
        setWaiting(false)
      })
  }

  if (loading) {
    return (
      <Stack
        sx={{ height: "50%" }}
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress />
      </Stack>
    )
  }

  return (
    <>
      <Typography
        variant="h5"
        sx={{ mb: 3 }}
      >
        <FontAwesomeIcon
          icon={faFileInvoice}
          style={{ marginRight: "10px" }}
        />
        {t("posts.pageTitleList")} / {t("posts.pageTitleDetails")}
      </Typography>
      <Stack
        direction="row"
        justifyContent="space-between"
        gap={2}
      >
        <PostForm
          formState={formState}
          updateTextField={updateTextField}
          validateTextField={validateTextField}
          updatePublicationStatus={updatePublicationStatus}
          images={images}
          formStatePristine={formStatePristine}
          handleSave={handleSave}
          handlePreview={handlePreview}
        />
        <PostImages
          postId={postId}
          images={images}
          refreshImages={refreshImages}
          ogImage={formState.ogImage.value}
          clearOgImage={clearOgImage}
          setWaiting={setWaiting}
        />
      </Stack>
      <Dialog open={waiting}>
        <DialogContent>
          <CircularProgress />
        </DialogContent>
      </Dialog>
    </>
  )
}

export default PostDetailsPage
