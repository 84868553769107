import React, { useEffect, useState } from "react"

import {
  faArrowLeft,
  faArrowLeftToLine,
  faArrowRight,
  faArrowRightToLine,
  faSearch,
  faTrashAlt,
} from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  Input,
  Link,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material"
import { Platform } from "@taiyoro/parse-stream-url"
import { useTranslation } from "react-i18next"

import LoadingBox from "../../components/LoadingBox"
import { StylePaper } from "../../components/StyleMaterialUI"
import type Game from "../../models/Taiyoro/Meta/Game"
import {
  deletePlatformGameMapping,
  fetchPlatformGameMappings,
  PLATFORM_CHOICES,
  savePlatformGameMapping,
} from "../../services/Taiyoro/dataCollection"
import type { PlatformGameMapping } from "../../services/Taiyoro/dataCollection"
import { fetchGames } from "../../services/Taiyoro/games"
import { localisedLabel } from "../../utils/i18n"

interface MappingSelectorProps {
  games: Array<Game>
  platformGameMapping: PlatformGameMapping
  updateMapping: (updatedMapping: PlatformGameMapping) => Promise<void>
}

const PAGE_SIZE = 100

const MappingSelector = ({ games, platformGameMapping, updateMapping }: MappingSelectorProps) => {
  const initiallySelectedGame = games.find((game) => game.id === platformGameMapping.gameId)

  const [value, setValue] = React.useState(initiallySelectedGame ?? null)
  const [inputValue, setInputValue] = React.useState<string>(
    initiallySelectedGame ? (localisedLabel(initiallySelectedGame) as string) : ""
  )

  useEffect(() => {
    setValue(initiallySelectedGame ?? null)
    setInputValue(localisedLabel(initiallySelectedGame) as string)
  }, [initiallySelectedGame])

  return (
    <Autocomplete
      options={games}
      getOptionLabel={(option) => localisedLabel(option) as string}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore This needs to be not undefined in order to be understood as a controlled input. Therefore we allow null, event though it's not a valid value.
      value={value}
      inputValue={inputValue}
      onInputChange={(_event, newInputValue) => {
        setInputValue(newInputValue)
      }}
      disableClearable
      clearOnBlur
      onChange={(_event, newValue) => {
        if (typeof newValue === "string") {
          return
        }
        setValue(newValue)
        void updateMapping({
          ...platformGameMapping,
          gameId: newValue.id,
        })
      }}
      renderInput={(params) => <TextField {...params} />}
    />
  )
}

const MappingPagination = ({
  page,
  setPage,
  totalCount,
  pageSize,
  disabled,
}: {
  page: number
  setPage: (page: number) => void
  totalCount: number
  pageSize: number
  disabled: boolean
}) => {
  const goToPage = (page: number) => setPage(page)

  const goToPreviousPage = () => goToPage(Math.max(page - 1, 1))

  const goToDoublePreviousPage = () => goToPage(Math.max(page - 2, 1))

  const goToTriplePreviousPage = () => goToPage(Math.max(page - 3, 1))

  const goToNextPage = () => goToPage(Math.min(page + 1, Math.ceil(totalCount / pageSize)))

  const goToDoubleNextPage = () => goToPage(Math.min(page + 2, Math.ceil(totalCount / pageSize)))

  const goToTripleNextPage = () => goToPage(Math.min(page + 3, Math.ceil(totalCount / pageSize)))

  const goToFirstPage = () => goToPage(1)

  const goToLastPage = () => goToPage(Math.ceil(totalCount / pageSize))

  const canGoToTriplePreviousPage = page > 3
  const canGoToDoublePreviousPage = page > 2
  const canGoToPreviousPage = page > 1
  const canGoToNextPage = page < Math.ceil(totalCount / pageSize)
  const canGoToDoubleNextPage = page < Math.ceil(totalCount / pageSize) - 1
  const canGoToTripleNextPage = page < Math.ceil(totalCount / pageSize) - 2
  const isFirstPage = page === 1
  const isLastPage = page === Math.ceil(totalCount / pageSize)

  return (
    <Box sx={{ position: "relative" }}>
      <Stack
        direction="row"
        gap={1}
        alignItems="center"
        justifyContent={"space-between"}
        sx={{ opacity: disabled ? 0.25 : 1 }}
      >
        <Stack
          direction="row"
          gap={1}
        >
          <Button
            onClick={goToFirstPage}
            disabled={isFirstPage}
            variant="contained"
            color="primary"
          >
            <FontAwesomeIcon icon={faArrowLeftToLine} />
          </Button>
          <Button
            onClick={goToPreviousPage}
            disabled={!canGoToPreviousPage}
            variant="contained"
            color="primary"
          >
            <FontAwesomeIcon icon={faArrowLeft} />
          </Button>
        </Stack>
        <Stack
          direction="row"
          gap={2}
          alignItems="center"
        >
          {canGoToTriplePreviousPage && <Button onClick={goToTriplePreviousPage}>{page - 3}</Button>}
          {canGoToDoublePreviousPage && <Button onClick={goToDoublePreviousPage}>{page - 2}</Button>}
          {canGoToPreviousPage && <Button onClick={goToPreviousPage}>{page - 1}</Button>}
          <Box px={2}>{page}</Box>
          {canGoToNextPage && <Button onClick={goToNextPage}>{page + 1}</Button>}
          {canGoToDoubleNextPage && <Button onClick={goToDoubleNextPage}>{page + 2}</Button>}
          {canGoToTripleNextPage && <Button onClick={goToTripleNextPage}>{page + 3}</Button>}
        </Stack>
        <Stack
          direction="row"
          gap={1}
        >
          <Button
            onClick={goToNextPage}
            disabled={!canGoToNextPage}
            variant="contained"
            color="primary"
          >
            <FontAwesomeIcon icon={faArrowRight} />
          </Button>
          <Button
            onClick={goToLastPage}
            disabled={isLastPage}
            variant="contained"
            color="primary"
          >
            <FontAwesomeIcon icon={faArrowRightToLine} />
          </Button>
        </Stack>
      </Stack>
      {disabled && <Box sx={{ position: "absolute", inset: 0 }} />}
    </Box>
  )
}

const PLATFORM_GAME_MAPPINGS_PLATFORMS = PLATFORM_CHOICES.filter((platformChoice) => {
  return platformChoice.platform !== Platform.Afreeca && platformChoice.platform !== Platform.YouTube
})

const PlatformGameMappings = () => {
  const { t } = useTranslation(["data-collection", "common"])

  const [selectedPlatform, setSelectedPlatform] = useState(PLATFORM_GAME_MAPPINGS_PLATFORMS[0])
  const [platform, setPlatform] = useState<Platform | null>(null)
  const [games, setGames] = useState<Array<Game> | null>(null)
  const [loading, setLoading] = useState(false)
  const [platformGameMappings, setPlatformGameMappings] = useState<Array<PlatformGameMapping>>([])
  const [deleteMappingState, setDeleteMappingState] = useState<PlatformGameMapping | null>(null)

  const [currentPage, setCurrentPage] = useState(1)

  const [platformGameMappingKeyword, setPlatformGameMappingKeyword] = useState("")

  const getPlatformGameMappingsForPlatform = async (platform: Platform) => {
    setLoading(true)
    await fetchPlatformGameMappings(platform).then((fetchedResponse) => {
      setPlatformGameMappings(fetchedResponse)
      setPlatform(platform)
      setLoading(false)
    })
  }

  const onDelete = async () => {
    if (!deleteMappingState) return

    const success = await deletePlatformGameMapping(
      selectedPlatform.platform,
      deleteMappingState.platformGameId
    )
    if (success) {
      const updatedPlatformGameMappings = ([...platformGameMappings] as Array<PlatformGameMapping>).filter(
        (platformGameMapping) => {
          return !(
            platformGameMapping.platformGameId === deleteMappingState.platformGameId &&
            platformGameMapping.platformName === deleteMappingState.platformName
          )
        }
      )
      setPlatformGameMappings(updatedPlatformGameMappings)
      setDeleteMappingState(null)
    }
  }

  const load = async () => {
    await fetchGames().then((games) => {
      setGames(games)
    })
  }

  useEffect(() => {
    void load()
  }, [])

  const handleUpdate = async (updatedPlatformGameMapping: PlatformGameMapping) => {
    if (!platform) return

    const success = await savePlatformGameMapping(
      platform,
      updatedPlatformGameMapping.platformGameId,
      updatedPlatformGameMapping.gameId
    )
    if (success) {
      const index = platformGameMappings.findIndex((platformGameMapping) => {
        return (
          platformGameMapping.platformGameId === updatedPlatformGameMapping.platformGameId &&
          platformGameMapping.platformName === updatedPlatformGameMapping.platformName
        )
      })
      const updatedPlatformGameMappings = [...platformGameMappings]
      updatedPlatformGameMappings[index] = updatedPlatformGameMapping
      setPlatformGameMappings(updatedPlatformGameMappings)
    }
  }

  const getPlatformGameUrl = (platformGameMapping: PlatformGameMapping) => {
    if (platform === Platform.Twitch) {
      return encodeURI(`https://www.twitch.tv/directory/game/${platformGameMapping.platformGameName}`)
    }
    if (platform === Platform.Mildom) {
      return encodeURI(`https://www.mildom.com/channel/${platformGameMapping.platformGameId}`)
    }
    if (platform === Platform.OpenRec) {
      return encodeURI(`https://www.openrec.tv/game/${platformGameMapping.platformGameId}`)
    }
  }

  return (
    <>
      <StylePaper>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          spacing={3}
        >
          <Grid item>
            {selectedPlatform && (
              <Select
                required
                value={selectedPlatform.label}
                onChange={(e) => {
                  setSelectedPlatform(
                    PLATFORM_CHOICES.find((platformChoice) => platformChoice.label === e.target.value)!
                  )
                }}
              >
                {PLATFORM_GAME_MAPPINGS_PLATFORMS.map((platformChoice) => (
                  <MenuItem
                    key={platformChoice.label}
                    value={platformChoice.label}
                  >
                    {platformChoice.label}
                  </MenuItem>
                ))}
              </Select>
            )}
          </Grid>
        </Grid>
        <Box mt="12px">
          <Button
            onClick={() => void getPlatformGameMappingsForPlatform(selectedPlatform.platform)}
            variant="contained"
            color="primary"
            startIcon={
              <FontAwesomeIcon
                icon={faSearch}
                size="1x"
              />
            }
          >
            {t("explore.fetch")}
          </Button>
        </Box>

        {loading && <LoadingBox />}
        {!loading && games && platformGameMappings.length > 0 && (
          <Stack
            gap={4}
            mt={4}
          >
            <Box>
              <Input
                placeholder={t("platformGameMappings.filterByGame")}
                type="text"
                value={platformGameMappingKeyword}
                onChange={(e) => {
                  if (currentPage !== 1) setCurrentPage(1)
                  setPlatformGameMappingKeyword(e.target.value)
                }}
              />
            </Box>
            <MappingPagination
              page={currentPage}
              setPage={setCurrentPage}
              totalCount={platformGameMappings.length}
              pageSize={PAGE_SIZE}
              disabled={platformGameMappingKeyword !== ""}
            />
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t("platformGameMappings.table.gameId")}</TableCell>
                  <TableCell>{t("platformGameMappings.table.gameName")}</TableCell>
                  <TableCell>{t("platformGameMappings.table.mapping")}</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {platformGameMappings
                  .filter((mapping) => {
                    return mapping.platformGameName
                      .toLowerCase()
                      .includes(platformGameMappingKeyword.toLowerCase())
                  })
                  .slice(
                    Math.max(0, currentPage - 1) * PAGE_SIZE,
                    Math.min(platformGameMappings.length, currentPage * PAGE_SIZE)
                  )
                  .map((platformGameMapping, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <Link
                          href={getPlatformGameUrl(platformGameMapping)}
                          target="_blank"
                          underline="hover"
                        >
                          {platformGameMapping.platformGameId}
                        </Link>
                      </TableCell>
                      <TableCell>{platformGameMapping.platformGameName}</TableCell>
                      <TableCell>
                        <MappingSelector
                          platformGameMapping={platformGameMapping}
                          games={games}
                          updateMapping={handleUpdate}
                        />
                      </TableCell>
                      <TableCell>
                        <Tooltip title={t("common:actions.delete")}>
                          <IconButton
                            color="error"
                            onClick={() => setDeleteMappingState(platformGameMapping)}
                            sx={{ width: "32px", height: "32px" }}
                          >
                            <FontAwesomeIcon icon={faTrashAlt} />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            <MappingPagination
              page={currentPage}
              setPage={setCurrentPage}
              totalCount={platformGameMappings.length}
              pageSize={PAGE_SIZE}
              disabled={platformGameMappingKeyword !== ""}
            />
          </Stack>
        )}
      </StylePaper>
      {deleteMappingState && (
        <Dialog
          open
          onClose={() => setDeleteMappingState(null)}
        >
          <DialogContent>
            <Typography>{t("platformGameMappings.deleteConfirmation")}</Typography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => void onDelete()}
              autoFocus
              variant="outlined"
              color="error"
            >
              {t("common:actions.delete")}
            </Button>
            <Button
              onClick={() => setDeleteMappingState(null)}
              color="primary"
              autoFocus
              variant="contained"
            >
              {t("common:actions.cancel")}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  )
}

export default PlatformGameMappings
