import type { Tag } from "models/Taiyoro/Meta/Tag"
import type moment from "moment-timezone"

import type Caster from "./Meta/Caster"
import type Organizer from "./Meta/Organizer"
import type Producer from "./Meta/Producer"
import EventSponsor from "./Sponsor"
import type EventDate from "./eventDate"
import type { EventGame } from "./eventGame"

export enum RegistrationType {
  Player = "USER",
  Team = "TEAM",
}

export enum PublishedState {
  Draft = 0,
  Published = 1,
}

enum ValidationError {
  InvalidName = 0,
  NoGames,
  NoDates,
  DateNotNamed,
  DateRangeTooLong,
  DateEndBeforeDateStart,
}

export interface AnalyticsData {
  viewsLiveMinutesWatched
  viewsLiveConcurrentAverage
  viewsLiveConcurrentPeak
  averageDailyMinutesWatched
}

export enum EventTier {
  TIER_1 = 1,
}

export type EventListSearchParams = {
  mode: "calendar" | "tags"
  month?: moment.Moment
  filterTags?: Array<Tag>
}

export type EventListItem = {
  id: string
  name: string
  origin: string
  published: PublishedState.Draft | PublishedState.Published
  dates: EventDate[]
  games: any[]
  analytics: AnalyticsData
  notes: string
  tags: Tag[]
}

export default class Event {
  id: string | null
  // eslint-disable-next-line
  name: string
  urlSlug: string
  taiyoroRating: number | null
  budget: string | null
  currency: string
  eventUrl: string
  tier: EventTier
  twitterHandle: string
  hashtag: string
  hideVideoEmbeds: 0 | 1
  imageUrl: string
  description: string
  origin: string
  published: PublishedState.Draft | PublishedState.Published
  dates: EventDate[]
  games: EventGame[]
  organizers: Organizer[]
  producers: Producer[]
  sponsors: EventSponsor[]
  casters: Caster[]
  tags: string[]
  notes: string
  registrationUrl: string | null
  registrationType: RegistrationType | null
  registrationLimit: number | null
  registrationEnabled: number
  registrationStartDatetime: moment.Moment | null
  registrationEndDatetime: moment.Moment | null
  analytics?: AnalyticsData
  owner?: string
  lastUpdater?: string
  createdAt?: Date | null
  cachedEarliestDate: moment.Moment | null

  constructor(
    id: string | null,
    name: string | null,
    urlSlug: string | null,
    taiyoroRating: number | null,
    budget: string | null,
    currency: string | null,
    eventUrl: string,
    tier: EventTier,
    twitterHandle: string,
    hashtag: string,
    hideVideoEmbeds: 0 | 1,
    imageUrl: string,
    description: string,
    origin: string,
    published: PublishedState | 0 | 1,
    dates: EventDate[],
    games: EventGame[],
    organizers: Organizer[],
    sponsors: EventSponsor[],
    producers: Producer[],
    casters: Caster[],
    tags: string[],
    notes: string | null,
    registrationUrl: string | null,
    registrationType: RegistrationType | null,
    registrationLimit: number,
    registrationEnabled: number,
    registrationStartDatetime: moment.Moment,
    registrationEndDatetime: moment.Moment,
    analytics?: AnalyticsData,
    owner?: string,
    lastUpdater?: string,
    createdAt?: any
  ) {
    this.id = id || null
    this.name = name || ""
    this.urlSlug = urlSlug
    this.taiyoroRating = taiyoroRating
    this.budget = budget || null
    this.currency = currency || "JPY"
    this.eventUrl = eventUrl
    this.tier = tier
    this.twitterHandle = twitterHandle
    this.hashtag = hashtag
    this.hideVideoEmbeds = hideVideoEmbeds
    this.imageUrl = imageUrl
    this.description = description
    this.origin = origin
    this.published = published
    this.dates = dates || []
    this.games = games || []
    this.organizers = organizers || []
    this.sponsors = sponsors.map(
      (s) =>
        new EventSponsor(
          s.id,
          s.name,
          s.revenue,
          s.currency,
          s.contributionTypes,
          s.priority,
          s.allDates,
          s.dateIds
        )
    )
    this.producers = producers || []
    this.casters = casters || []
    this.tags = tags || []
    this.notes = notes
    this.registrationUrl = registrationUrl
    this.registrationType = registrationType
    this.registrationLimit = registrationLimit
    this.registrationEnabled = registrationEnabled
    this.registrationStartDatetime = registrationStartDatetime
    this.registrationEndDatetime = registrationEndDatetime
    this.analytics = analytics
    this.owner = owner
    this.lastUpdater = lastUpdater
    this.createdAt = (createdAt && new Date(createdAt)) || null

    // Provides a substantial date sorting performance boost
    this.cachedEarliestDate = null
  }

  getEarliestDate() {
    if (this.cachedEarliestDate) {
      return this.cachedEarliestDate
    }

    if (this.dates.length === 0) {
      return null
    }
    let i = 0
    let first = null

    do {
      if (first === null || this.dates[i].startTime.isBefore(first)) {
        first = this.dates[i].startTime
      }
      i++
    } while (i < this.dates.length)

    this.cachedEarliestDate = first

    return this.cachedEarliestDate
  }

  validate(): ValidationError | null {
    if (!this.name || this.name.length === 0) {
      return ValidationError.InvalidName
    }
    for (let i = 0; i < this.dates.length; i++) {
      if (!this.dates[i].name || this.dates[i].name.length === 0) {
        return ValidationError.DateNotNamed
      }
      if (Math.ceil(this.dates[i].endTime.diff(this.dates[i].startTime, "minutes")) / 60 > 24) {
        return ValidationError.DateRangeTooLong
      }
      if (this.dates[i].endTime.unix() <= this.dates[i].startTime.unix()) {
        return ValidationError.DateEndBeforeDateStart
      }
    }

    return null
  }
}
